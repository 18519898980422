import { Icon } from '@blueprintjs/core'
import { NavMenu, PageFooter } from '../../componets/interfaces'

import { Link } from 'react-router-dom'
import { useDynamicTemplateStyles } from '../../hooks/dynamicTemplateStyle'
import '../app.css'

function ArticleLink(props: { name: string; subtitle: string; to: string }) {
  const { name, subtitle, to } = props
  return (
    <Link to={to} style={{ marginLeft: '42px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <Icon icon="saved"></Icon>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          <h2 style={{ margin: '1px' }}>{name}</h2>
          <span style={{ margin: '1px' }}>{subtitle}</span>
        </div>
      </div>
    </Link>
  )
}

function ArticlesPage() {
  const classes = useDynamicTemplateStyles({
    footerHeight: '5rem',
    headerHeight: '5rem',
  })

  return (
    <div className={`scrollable-page img-background`}>
      <div className={`${classes.pageHeader}`}>
        <div style={{ flex: '1' }} />
        <NavMenu />
      </div>

      <div className={'page-content-container'}>
        <div className={'page-content'}>
          <div
            style={{
              padding: '1rem',
              overflow: 'auto',
            }}
          >
            <h1>Articles</h1>

            <div style={{ height: '10px' }}></div>

            <p>We have provided some guidane on Visas and immigration in the articles below.</p>

            <p>Carefully curated information to help you on your journey!</p>

            <div
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '2em', marginTop: '2.5em' }}
            >
              <ArticleLink
                name="What is an E3 Visa?"
                subtitle="A quick guide to the E3 visa"
                to="/articles/what-is-an-e3-visa"
              />

              <ArticleLink
                name="What is a B Visa?"
                subtitle="A quick guide to the Visitor Visa"
                to="/articles/what-is-a-b-visa"
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ flex: '1' }} />
      <div className={classes.pageFooter}>
        <PageFooter />
      </div>
    </div>
  )
}

export default ArticlesPage
