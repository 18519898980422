import React from 'react'
import { Button, Card, Icon, Tag, AnchorButton } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { FeedbackInput, ServiceIcon, AutoCollapse, ChatExamples } from '../interfaces'
import type { ChatMessage, ChatState, SetChatState, User, WebLink } from '../../types'
import './chat.css'
import { postMessageRating } from '../../api'
import toaster from '../../toaster'

type ChatMessageProps = {
  index: number
  message: String
  user_short_id: String
  disabled?: boolean
  message_id: String
  thread_id: String
  links: Array<WebLink>
  suggestions: Array<String>
  downloads: Record<string, String>
  updateMessage?: (arg0: String) => void
}

function transform_text_with_links(text: String) {
  const readable_name_pattern_regex = /\[([^\]]+)]\((https?:\/\/[^\s]+)\)/g
  const url_pattern_regex = /https?:\/\/[^\s]+/g

  const class_name = 'ai-chat-message-link'

  // Check if the text contains the readable name pattern
  if (readable_name_pattern_regex.test(text.toString())) {
    // Apply the transform readable Nnme url pattern
    return text.replace(
      readable_name_pattern_regex,
      (match, readableName, url) => `<a class=${class_name} href="${url}">${readableName}</a>`
    )
  }

  // Apply the wrap urls with anchors
  return text.replace(url_pattern_regex, (url) => `<a class=${class_name} href="${url}">${url}</a>`)
}

export function ChatMessage(props: ChatMessageProps) {
  const {
    index,
    message,
    user_short_id,
    disabled,
    message_id,
    thread_id,
    links,
    suggestions,
    downloads,
    updateMessage,
  } = props

  const [is_open_1, set_is_open_1] = React.useState<boolean>(false)
  const [is_open_2, set_is_open_2] = React.useState<boolean>(false)

  function WebLink(props: WebLink) {
    const { instruction, url, icon } = props

    return (
      <Button
        className="ai-chat-web-link"
        intent="primary"
        alignText="left"
        icon={<ServiceIcon icon={icon} />}
        onClick={() => {
          window.open(url.toString(), '_blank')
        }}
      >
        {instruction}
      </Button>
    )
  }

  function AiMessage() {
    const _message = transform_text_with_links(message)

    // const suggestions = [
    //   'What are the different types of work visas available in the US?',
    //   'What is the process for obtaining a green card?',
    // ]

    // const downloads = {
    //   'AR-11': 'https://www.uscis.gov/sites/default/files/document/forms/ar-11.pdf',
    //   'AR-26': 'https://www.uscis.gov/sites/default/files/document/forms/ar-26.pdf',
    // }

    return (
      <div key={String(index)}>
        <div
          className="ai-chat-message"
          style={{
            display: 'flex',
          }}
        >
          <div style={{ color: 'white', height: '100%', flexGrow: 1 }}>
            <div
              style={{
                whiteSpace: 'pre-line',
                color: 'white',
              }}
              dangerouslySetInnerHTML={{
                __html: _message,
              }}
            ></div>

            {Object.keys(downloads)?.length > 0 ? <br /> : null}

            {Object.keys(downloads)?.length > 0 ? (
              <div
                id="link-container"
                style={{
                  paddingTop: '0.8rem',
                  display: 'flex',
                  flexDirection: 'column',
                  flexFlow: 'wrap',
                  gap: '0.5rem',
                  justifyContent: 'flex-start',
                }}
              >
                <div>Downloads</div>
                <hr style={{ width: '100%', margin: 0 }} />
                {Object.keys(downloads).map((file_name, index) => (
                  <AnchorButton
                    key={index}
                    minimal
                    text={file_name}
                    icon={<Icon icon="download" color="#D4BAEB" />}
                    style={{
                      color: 'white',
                    }}
                    target="_blank"
                    href={downloads[file_name]}
                  />
                ))}
              </div>
            ) : null}

            {suggestions?.length > 0 ? <br /> : null}

            {suggestions?.length > 0 ? (
              <div
                id="link-container"
                style={{
                  paddingTop: '0.8rem',
                  display: 'flex',
                  flexDirection: 'column',
                  flexFlow: 'wrap',
                  gap: '0.5rem',
                  justifyContent: 'flex-start',
                }}
              >
                <div>Follow Ups</div>
                <hr style={{ width: '100%', margin: 0 }} />
                {suggestions.map((suggestion, index) => (
                  <Button
                    disabled={disabled}
                    key={index}
                    large
                    minimal
                    text={suggestion}
                    icon={<Icon icon="send-message" color="#FFC659" />}
                    style={{
                      color: 'white',
                    }}
                    onClick={() => {
                      if (updateMessage) updateMessage(suggestion)
                    }}
                  />
                ))}
              </div>
            ) : null}

            {links?.length > 0 ? (
              <div
                id="link-container"
                style={{
                  paddingTop: '0.8rem',
                }}
              >
                <AutoCollapse text="Citations">
                  <div
                    style={{
                      paddingTop: '0.5rem',
                      display: 'flex',
                      flexDirection: 'column',
                      flexFlow: 'wrap',
                      gap: '0.5rem',
                      justifyContent: 'flex-start',
                    }}
                  >
                    {links.map((webLink, index) => (
                      <WebLink key={index} instruction={webLink.instruction} url={webLink.url} icon={webLink.icon} />
                    ))}
                  </div>
                </AutoCollapse>
              </div>
            ) : null}
          </div>
          <div>
            <div
              style={{
                width: 'fit-content',
                float: 'right',
              }}
            >
              <Popover2
                disabled={disabled}
                interactionKind="click"
                placement="top-end"
                isOpen={is_open_1}
                canEscapeKeyClose={true}
                onClose={() => {
                  set_is_open_1(false)
                }}
                content={
                  <FeedbackInput
                    intent="positive"
                    handleFeedback={(comment: String) => {
                      postMessageRating(thread_id, message_id, comment, 1)
                        .then((res) => {
                          if (res.status == 201) {
                            toaster.show({
                              message: 'Thank you for the feedback!',
                              timeout: 3000,
                              intent: 'success',
                            })
                          }

                          if (res.status == 409) {
                            toaster.show({
                              message: 'This response has already been rated.',
                              timeout: 3000,
                              intent: 'none',
                            })
                          }
                          console.log(res)
                        })
                        .catch((err) => {
                          console.warn(err)
                        })
                    }}
                    closePopover={() => {
                      set_is_open_1(false)
                    }}
                  />
                }
                minimal
              >
                <Button
                  disabled={disabled}
                  icon="thumbs-up"
                  minimal
                  onClick={() => {
                    set_is_open_1(true)
                  }}
                />
              </Popover2>

              <Popover2
                disabled={disabled}
                interactionKind="click"
                placement="top-end"
                isOpen={is_open_2}
                onClose={() => {
                  set_is_open_2(false)
                }}
                content={
                  <FeedbackInput
                    intent="negative"
                    handleFeedback={(comment: String) => {
                      postMessageRating(thread_id, message_id, comment, -1)
                        .then((res) => {
                          if (res.status == 201) {
                            toaster.show({
                              message: 'Thank you for the feedback!',
                              timeout: 3000,
                              intent: 'success',
                            })
                          }

                          if (res.status == 409) {
                            toaster.show({
                              message: 'This response has already been rated.',
                              timeout: 3000,
                              intent: 'none',
                            })
                          }
                        })
                        .catch((err) => {
                          console.warn(err)
                        })
                    }}
                    closePopover={() => {
                      set_is_open_2(false)
                    }}
                  />
                }
                minimal
              >
                <Button
                  disabled={disabled}
                  icon="thumbs-down"
                  minimal
                  onClick={() => {
                    set_is_open_2(true)
                  }}
                />
              </Popover2>
            </div>
          </div>
        </div>
        <br />
      </div>
    )
  }

  function UserMessage() {
    return (
      <div key={String(index)}>
        <div className="user-chat-message">
          <div style={{ color: 'black', height: '100%' }}>
            <div
              style={{
                whiteSpace: 'pre-line',
              }}
            >
              {message}
            </div>
          </div>
        </div>
        <br />
      </div>
    )
  }

  return user_short_id == 'ai' ? <AiMessage /> : <UserMessage />
}

type ChatDisplayProps = {
  user: User
  state: ChatState
  setState: SetChatState
  updateMessage: (msg: String) => void
}

function ChatDisplay(props: ChatDisplayProps) {
  const { user, state, setState, updateMessage } = props
  const chat_anchor_ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!chat_anchor_ref.current) return
    chat_anchor_ref.current.scrollIntoView()
  }, [state.chat_history])

  if (state.chat_history.length == 0) {
    return (
      <div
        style={{
          height: '100%',
        }}
      >
        <ChatExamples
          example_statements={[
            'What forms do I need to fill out to complete the J-1 visa?',
            'What are the requirements for B-2 Tourism visa?',
            'Can I apply for a fee waiver for N-400 Naturalization application?',
            'My greencard is approved last December 2022. when is the best time to change employer? im not changing career or profession. Just seeking out better pay same line of work.',
            '¿Cuál es la lista de documentos que necesita un beneficiario para ir a la entrevista en República Dominicana, incluyendo aquellos que aplican para visas K-1/K-2?'
          ]}
          updateMessage={updateMessage}
        />
      </div>
    )
  }

  return (
    <div className="chat-msgs-container">
      {state.chat_history.slice().map((chat_msg, index) => {
        return (
          <ChatMessage
            key={String(index)}
            index={index}
            message={chat_msg.message}
            user_short_id={chat_msg.user_short_id}
            message_id={chat_msg.message_id}
            thread_id={String(state.thread_id)}
            links={chat_msg.links}
            suggestions={chat_msg.suggestions}
            downloads={chat_msg.downloads}
            updateMessage={updateMessage}
          />
        )
      })}
      <div ref={chat_anchor_ref}></div>
    </div>
  )
}

export default ChatDisplay
