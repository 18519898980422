import { AnchorButton, Button, Card, InputGroup, Tag } from '@blueprintjs/core'
import React from 'react'
import Typed from 'typed.js'
import { ChatMessage } from '../componets/chat/chatDisplay'
import { PageFooter } from '../componets/interfaces'
import { useDynamicTemplateStyles } from '../hooks/dynamicTemplateStyle'
import './app.css'
import './landingPage.css'

const man = new URL('../assets/man.svg', import.meta.url)
const bg = new URL('../assets/background_centered_bridge.svg', import.meta.url)

// customer images
const moe = new URL('../assets/moe.png', import.meta.url)
const jp = new URL('../assets/jp.png', import.meta.url)
const ryan = new URL('../assets/ryan.png', import.meta.url)
const liam = new URL('../assets/liam.png', import.meta.url)

const logo_image_url = new URL('../assets/low_poly_torch.png', import.meta.url)

function CustomerReviewCard(props: { name: string; img_url: string; children: React.ReactNode }) {
  const { name, img_url } = props
  return (
    <Card elevation={3} className={'testimonial'}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <img
          loading="lazy"
          src={img_url}
          style={{
            height: '4.5rem',
            borderRadius: '4.5rem',
            border: '1px gold solid',
          }}
          alt="Customer Profile Icon"
        />
        <h2>{name}</h2>
      </div>
      <br />
      <div>{props.children}</div>
    </Card>
  )
}

function LandingPage() {
  const classes = useDynamicTemplateStyles({
    footerHeight: '5rem',
    headerHeight: '5rem',
  })

  const input_ref = React.useRef<HTMLInputElement>()

  React.useLayoutEffect(() => {
    const typed = new Typed(input_ref.current, {
      strings: [
        'How do I apply for an E3 visa?',
        'How do I help my husband work in the US?',
        '移民到美国是否有语言要求，例如需要通过英语水平测试？',
        '¿Cuál es el proceso para solicitar una H-2A visa?',
        'Tell me more about the h1b process.',
        'Comment savoir quel visa me convient le mieux?',
        "Y a-t-il des exigences spécifiques dont j'ai besoin pour un visa?",
        'كيف تتعامل الولايات المتحدة مع الرعاية الصحية والتأمين للمهاجرين؟',
        '移民申請が処理されている間、アメリカで働くことはできますか？',
        'यूएस यूएसए में आप्रवासन के बाद मेरे अधिकार और कर्तव्य क्या हैं?',
      ],
      typeSpeed: 50,
      loop: true,
      smartBackspace: true,
      backDelay: 1750,
    })

    return () => {
      typed.destroy()
    }
  }, [input_ref])

  return (
    <div className={'scrollable-page'}>
      <div className={'banner'}>
        <div className="banner-img-container">
          <img src={bg.toString()} alt="Background image for Immigration GPT" />
        </div>
        <div className="banner-items">
          <div className="banner-padding-top" />
          <img src={logo_image_url.toString()} style={{ height: '4rem' }} alt="Immigration GPT Logo" />
          <h1>Immigration GPT</h1>
          <p>An AI for your US immigration questions</p>
          <br />
          <div>
            <AnchorButton
              large
              style={{
                borderRadius: '8px',
                backgroundColor: '#383258',
                color: 'whitesmoke',
              }}
              text="Start Chat"
              href="/api/google"
            />
          </div>
          <div className="banner-padding-bottom" />
        </div>
      </div>

      <div className={'page-content-container'}>
        <div className={'page-content'}>
          <div
            style={{
              padding: '1rem',
            }}
          >
            <h1>Product Demo</h1>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Card elevation={3} style={{ maxWidth: '600px' }}>
                <ChatMessage
                  key={0}
                  index={0}
                  message={'I want my mother from Argentina to come visit me, what do I need to do.'}
                  user_short_id={'sw'}
                  message_id={'-1'}
                  thread_id={'-1'}
                  disabled
                  links={[]}
                  suggestions={[]}
                  downloads={{}}
                />
                <ChatMessage
                  key={1}
                  index={1}
                  message={`To bring your mother from Argentina to the US for a visit, she will need to apply for a B-2 tourist visa.

                  1. Ensure your mother has a valid passport.

                  2. Your mother should complete the DS-160 form online.

                  3. Pay the visa application fee (check the U.S. Embassy or Consulate website for fee details).

                  4. Schedule a visa interview appointment at the U.S. Embassy Buenos Aires.`}
                  user_short_id={'ai'}
                  message_id={'-1'}
                  thread_id={'-1'}
                  disabled
                  links={[
                    {
                      instruction: 'ds 160 online nonimmigrant visa application',
                      url: 'https://ceac.state.gov/genniv/',
                      icon: 'DHS',
                    },
                    {
                      instruction: 'U.S. Embassy Buenos Aires.',
                      url: 'https://ar.usembassy.gov/embassy/buenos-aires/',
                      icon: 'DOS',
                    },
                  ]}
                  suggestions={[
                    'What is the visa application fee for a B-2 visitor visa?',
                    'How long does it take to get a B-2 visitor visa?',
                  ]}
                  downloads={{
                    'DS-160': 'https://ceac.state.gov/genniv/',
                  }}
                />
                <InputGroup
                  disabled
                  style={{
                    color: 'black',
                  }}
                  round
                  large
                  inputRef={input_ref}
                  type="text"
                  rightElement={<Button minimal disabled icon="send-message" />}
                />
                <div style={{ marginTop: '0.6rem' }}>
                  <Tag intent="warning" minimal>
                    Alpha
                  </Tag>
                </div>
              </Card>
            </div>
          </div>

          <div
            style={{
              padding: '0.5rem',
              margin: '0.5rem',
            }}
          >
            <h1>Is this for me?</h1>

            <div
              style={{
                gap: '1rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Card elevation={3}>
                <h2 style={{ margin: 0 }}>You need help understanding the options</h2>
                <br />
                <div>
                  If you're having difficulty understanding USCIS information or struggling to find accurate results on
                  Google, give us a try.
                </div>
              </Card>

              <Card elevation={3}>
                <h2 style={{ margin: 0 }}>You've used community forums before</h2>
                <br />
                <div>
                  Think of this like a community forum... without the wait time for a response! Ask your questions
                  freely and get answers quickly.
                </div>
              </Card>

              <Card elevation={3}>
                <h2 style={{ margin: 0 }}>You want another opinion</h2>
                <br />
                <div>
                  Your friend said one thing, a lawyer said another, and the community forum said something else!
                </div>
              </Card>
            </div>
          </div>

          <div
            style={{
              padding: '0.5rem',
              margin: '0.5rem',
            }}
          >
            <h1>ImmigrationGPT vs ChatGPT?</h1>
            <Card
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '.9rem',
              }}
              elevation={3}
            >
              <div>
                <h2 style={{ margin: 0 }}>ChatGPT</h2>
                <ul style={{ paddingLeft: '1rem' }}>
                  <li>Does not have any government documents</li>
                  <li>It can give incorrect information</li>
                  <li>Not made for US Immigration</li>
                </ul>
              </div>
              <hr style={{ marginRight: '1rem', marginLeft: '0.5rem' }}></hr>
              <div>
                <h2 style={{ margin: 0 }}>ImmigrationGPT</h2>
                <ul style={{ paddingLeft: '1rem' }}>
                  <li>It has access to up-to-date government docuemnts</li>
                  <li>It has access to government forms</li>
                  <li>Gives factual information based on documentation</li>
                  <li>Built specifically for US Immigration 🇺🇸</li>
                </ul>
              </div>
            </Card>
          </div>

          {/* <div
            style={{
              padding: '1rem',
            }}
          >
            <h1>Support our operation</h1>

            <div style={{ display: 'block', justifyContent: 'center' }}>
              <Card
                elevation={3}
                style={{
                  width: '100%',
                  maxWidth: '600px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <h2 style={{ marginTop: '0' }}>We provide our service for free</h2>
                <p>The funds that keep our service alive are limited!</p>
                <p>Help us continue our mission to foster innovation in America by donating today</p>
                <br />
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <Button
                    large
                    rightIcon={<Icon icon="heart" color="#383258" />}
                    text="Donate today"
                    style={{
                      color: '#383258',
                      backgroundColor: 'gold',
                      borderRadius: '7px',
                    }}
                    onClick={() => {
                      window.open('https://donate.stripe.com/6oEbMge469FTbqU4gg', '_blank')
                    }}
                  />
                </div>
                <br />
              </Card>
              <br />

              <p
                style={{
                  fontSize: '0.75rem',
                }}
              >
                *Each user is allotted credits. Credits get refreshed every 30 days. Additional credits can be purchased
                if necessary
              </p>
            </div>
          </div> */}

          <div
            style={{
              padding: '1rem',
            }}
          >
            <h1>People Love it!</h1>
            <CustomerReviewCard name={'Mohammed N.'} img_url={moe.toString()}>
              “Immigration GPT is a great way to get your questions answered. No more confusing pages and long waits
              behind the phone to talk to USCIS agents.
            </CustomerReviewCard>
            <CustomerReviewCard name={'Ryan K.'} img_url={ryan.toString()}>
              "Its availability 24/7 and ability to provide quick, accurate answers have been invaluable to me. I highly
              recommend Immigration GPT to anyone seeking reliable guidance on their immigration journey."
            </CustomerReviewCard>
            <CustomerReviewCard name={'Yanis P.'} img_url={jp.toString()}>
              "Immigration GPT made extending my OPT a breeze, offering concise advice and quick answers to my
              questions. A game-changer in immigration assistance!"
            </CustomerReviewCard>
            <CustomerReviewCard name={'Liam B.'} img_url={liam.toString()}>
              "I was lost navigating the US GreenCard process, but ImmigrationGPT agent provided clear answers and
              support"
            </CustomerReviewCard>
          </div>

          <div style={{ display: 'flex', paddingLeft: '1rem', paddingRight: '1rem' }}>
            <img
              loading="lazy"
              src={man.toString()}
              style={{
                width: '6rem',
              }}
              alt="Man about to travel"
            />
            <div
              style={{
                flex: '1',
                display: 'grid',
              }}
            >
              <div
                style={{
                  placeSelf: 'center',
                  width: '',
                }}
              >
                <AnchorButton
                  large
                  style={{
                    borderRadius: '8px',
                    backgroundColor: '#383258',
                    color: 'whitesmoke',
                  }}
                  text="Start Chat"
                  href="/api/google"
                />
              </div>
            </div>
            <div style={{ width: '6rem' }}></div>
          </div>
        </div>
      </div>

      <div className={classes.pageFooter} style={{ backgroundColor: 'whitesmoke' }}>
        <PageFooter />
      </div>
    </div>
  )
}

export default LandingPage
