import React from 'react'

import { Button, Card, Icon, Spinner, Tag, TextArea, useHotkeys } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'

import { useLoaderData, useNavigate } from 'react-router-dom'

import { submitMessageAndGenerateResponse } from '../actions/chat'
import { chatStateFromThread } from '../reducers/chat'

import ChatDisplay from '../componets/chat/chatDisplay'

import { LogoButton, PageFooter, ProfileNavMenu } from '../componets/interfaces'

import { ChatState, INIT_CHAT_STATE, Thread, ThreadInfo, User } from '../types'

import { useDynamicTemplateStyles } from '../hooks/dynamicTemplateStyle'

import './app.css'

function getRandomItem(arr: Array<String>) {
  // get random index value
  const randomIndex = Math.floor(Math.random() * arr.length)
  // get random item
  const item = arr[randomIndex]
  return item
}

function HowDoesThisWork() {
  return (
    <Card
      elevation={1}
      style={{
        maxWidth: '360px',
        borderRadius: '2px !important',
      }}
    >
      <p>
        <strong>Tips for Optimal Use:</strong>
      </p>
      <ul>
        <li>
          Be specific: The more detailed your question, the better the AI Agent can provide accurate and relevant
          information.
        </li>
        <li>
          Use clear language: Write your questions using simple and clear language to ensure the AI Agent understands
          your query.
        </li>
        <li>
          Be patient: While the AI Agent is designed to respond quickly, there may be times when it takes a few moments
          to process and respond to your question.
        </li>
      </ul>
      {/* <p>
        <em>
          Note: ur Immigration AI Agent is a powerful tool for answering immigration-related questions, it is not
          a substitute for professional legal advice. Always consult with an immigration attorney or expert for specific
          guidance on your individual situation.
        </em>
      </p> */}
      {/* <p>
        <strong>Support:</strong>
      </p>
      <p>
        If you encounter any issues while using the Immigration AI Agent or require additional assistance, please
        contact our support team at [support email address] or [support phone number]. Our team is ready to help you
        with any questions or concerns you may have.
      </p> */}
    </Card>
  )
}

type ChatLoaderData = {
  user: User
  user_threads: Array<ThreadInfo>
  current_thread: Thread | null
}

function Chat() {
  const navigate = useNavigate()
  const data = useLoaderData() as ChatLoaderData
  const { user, user_threads } = data

  let { current_thread } = data

  // This is a component short circuit that
  // initializes a useEffect hook and then returns an empty div
  if (user == null) {
    React.useEffect(() => {
      console.log('Not user logged in: redirecting to login')
      setTimeout(() => {
        navigate('/login')
      }, 250)
    }, [])

    return <div></div>
  }

  const classes = useDynamicTemplateStyles({
    footerHeight: '5rem',
    headerHeight: '5rem',
  })

  const [state, setState] = React.useState<ChatState>(
    current_thread ? chatStateFromThread(current_thread) : INIT_CHAT_STATE
  )
  const message_ref = React.useRef<HTMLInputElement>(null)
  const message = React.useRef<String>('')

  const handleSubmit = React.useCallback(() => {
    if (message.current == '') return
    if (state.model_state == 'GENERATING') return

    const new_message = message.current

    message.current = ''
    if (message_ref.current) {
      message_ref.current.value = ''
      message_ref.current.style.height = '61px'
    }

    submitMessageAndGenerateResponse(new_message, `${user.given_name.charAt(0)}`, state, setState)
  }, [message_ref, message, state])

  const updateMessage = React.useCallback(
    (new_message: String) => {
      message.current = new_message
      if (message_ref.current) message_ref.current.value = new_message.toString()
    },
    [message_ref, message]
  )

  // important: hotkeys array must be memoized to avoid infinitely re-binding hotkeys
  const hotkeys = React.useMemo(
    () => [
      {
        combo: 'enter',
        global: true,
        allowInInput: true,
        label: 'text input',
        onKeyDown: handleSubmit,
        preventDefault: true,
      },
    ],
    [handleSubmit]
  )

  const { handleKeyDown } = useHotkeys(hotkeys, { document: document })

  const calls_to_action = ['How can we help?', "Let's figure this out.", 'Chat with our Agent!']

  // this effect is needed to update
  // the state when we navigate to a historic thread
  React.useEffect(() => {
    if (!current_thread) {
      setState(INIT_CHAT_STATE)
      return
    }
    setState(chatStateFromThread(current_thread))
  }, [current_thread])

  // this effect manages the url update
  // once we send a message and recieve the first response,
  // then we set the url to the appropriate thread_id
  React.useEffect(() => {
    if (current_thread) return
    if (!state.thread_id) return
    if (state.chat_history.length != 2) return
    // navigate()
    window.history.replaceState(null, '', `/chat/${state.thread_id}`)
  }, [state])

  return (
    <div className={`${classes.wrapper} img-background`} onKeyDown={handleKeyDown}>
      <div className={classes.pageHeader}>
        <LogoButton />
        <div style={{ width: '1rem' }} />
        <Button
          minimal
          icon="plus"
          text="New Chat"
          onClick={() => {
            window.history.replaceState(null, '', `/chat`)
            setState(INIT_CHAT_STATE)
            current_thread = null
            // navigate('/chat')
          }}
        />
        <div style={{ flex: '1' }} />
        <ProfileNavMenu user={user} threads={user_threads} />
      </div>

      <div className={classes.pageContent}>
        <div className={classes.flexSpacer} />

        <ChatDisplay state={state} setState={setState} user={user} updateMessage={updateMessage} />

        <div>
          <div className={state.chat_history.length == 0 ? 'title-container' : 'title-container title-hidden'}>
            <h1
              style={{
                margin: 0,
                paddingBottom: '0.5rem',
              }}
            >
              {getRandomItem(calls_to_action)}
            </h1>
          </div>
        </div>

        <div
          id="text_input_container"
          style={{
            marginTop: '.25rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '0.2rem',
            }}
          >
            <Button
              large
              minimal
              icon={<Icon icon={'cross-circle'} size={20} />}
              onClick={(e) => {
                e.preventDefault()
                updateMessage('')
              }}
            />

            <TextArea
              fill
              large
              growVertically
              type="text"
              inputRef={message_ref}
              onChange={(e) => {
                message.current = e.target.value
              }}
            />

            <Button
              large
              minimal
              icon={
                <div style={{ width: '1rem', textAlign: 'center' }}>
                  {state.model_state == 'READY' ? (
                    <Icon icon={'send-message'} className={'send-message-icon'} size={20} />
                  ) : (
                    <Spinner size={20} intent="primary" />
                  )}
                </div>
              }
              onClick={(e) => {
                e.preventDefault()
                handleSubmit()
              }}
            />
          </div>

          <div
            style={{
              marginTop: '.5rem',
              display: 'flex',
            }}
          >
            <Tag intent="warning" minimal>
              Alpha
            </Tag>
            <div className={classes.flexSpacer} />
            <Popover2 interactionKind="click" placement="bottom-end" content={<HowDoesThisWork />} minimal>
              <Button minimal small text="How does this work?" rightIcon="info-sign" />
            </Popover2>
          </div>
        </div>

        {/* <div className={state.chat_history.length > 1 ? 'smooth-shrink' : 'pre-shrink'} /> */}
      </div>

      <div className={classes.pageFooter}>
        <PageFooter />
      </div>
    </div>
  )
}

export default Chat
