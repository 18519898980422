const DEFAULT_FETCH_OPTIONS: RequestInit = {
  credentials: 'include',
}

const LOCAL_HOST = 'http://localhost:3000/api'
const REMOTE_HOST = 'https://immigrationlaw.chat/api'

export function getBackendURL(): string {
  console.log(process.env.NODE_ENV)
  if (process.env.NODE_ENV === 'development') {
    return LOCAL_HOST
  }
  return REMOTE_HOST
}

function requestWrapper(url: string) {
  return fetch(url, DEFAULT_FETCH_OPTIONS)
    .then((res) => {
      if (res.ok) {
        return res.json()
      }
      // resolve instead of reject for
      // promise.all to function properly
      return Promise.resolve(null)
    })
    .catch(function (err) {
      return Promise.reject(err)
    })
}

export async function getUser() {
  let resp = await requestWrapper('/api/get_user')
  // TODO update the backend to return what we want
  if (resp !== null && resp !== undefined) {
    let ret = resp.google_user_data
    ret.credits = resp.user_data.credits
    return ret
  }
  return resp
}

export function getStripeConfig() {
  return requestWrapper('/api/payment/config')
}

export function getStripeSession(product_id: String) {
  return requestWrapper(`/api/payment/checkout/${product_id}`)
}

export function getUserThreads() {
  return requestWrapper(`/api/get_threads`)
}

export function getThread(thread_id: Number) {
  return requestWrapper(`/api/thread/${thread_id}`)
}

export function putNewChatMessage(message: String, thread_id: Number | null) {
  const new_message = {
    message: message,
    thread_id: thread_id,
  }
  console.log(new_message)
  return fetch('/api/message', {
    ...DEFAULT_FETCH_OPTIONS,
    method: 'POST',
    body: JSON.stringify(new_message, null, 4),
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export function postMessageRating(thread_id: String, message_id: String, comment: String, rating: Number) {
  const rating_json = {
    thread_id: thread_id,
    message_id: message_id,
    comment: comment,
    rating: rating,
  }

  return fetch('/api/message/rate', {
    ...DEFAULT_FETCH_OPTIONS,
    method: 'POST',
    body: JSON.stringify(rating_json),
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export function createThread() {
  return fetch('/api/thread', {
    ...DEFAULT_FETCH_OPTIONS,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
