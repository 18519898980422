import { useNavigate } from 'react-router-dom'
import { Button } from '@blueprintjs/core'

import './app.css'

function PurchaseSuccessPage() {
    const navigate = useNavigate()

    return (
        <div className="wrapper img-background">
            <div
                style={{
                    display: 'grid',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <div
                    style={{
                        backgroundColor: 'rgba(255,255,255, 0.4)',
                        minWidth: '25rem',
                        height: 'fit-content',
                        padding: '20px',
                        borderRadius: '10px',
                        placeSelf: 'center',
                    }}
                >
                    <h1
                        style={{
                            color: 'darkgreen',
                        }}
                    >
                        Purchase Success!
                    </h1>
                    <h2>Thank you</h2>
                    <Button minimal text="Back To Chat" icon="undo" onClick={() => navigate('/chat')} />
                </div>
            </div>

        </div>
    )
}

export default PurchaseSuccessPage
