import { NavMenu, PageFooter } from '../../componets/interfaces'
import { useDynamicTemplateStyles } from '../../hooks/dynamicTemplateStyle'
import '../app.css'

type Section = {
  title: string
  content: string
}

export type Article = {
  title: string
  subtitle: string
  sections: Section[]
}

type ArticleTemplateProps = {
  article: Article
}

function ArticleTemplatePage({ article }: ArticleTemplateProps) {
  const classes = useDynamicTemplateStyles({
    footerHeight: '5rem',
    headerHeight: '5rem',
  })

  return (
    <div className={`scrollable-page img-background`}>
      <div className={`${classes.pageHeader}`}>
        <div style={{ flex: '1' }} />
        <NavMenu />
      </div>

      <div className={'page-content-container'}>
        <div className={'page-content'}>
          <div
            style={{
              padding: '1rem',
              overflow: 'auto',
            }}
          >
            <h1>{article.title}</h1>
            <span>{article.subtitle}</span>
            {/* <div style={{ height: '10px' }}></div> */}

            <div
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '1em', marginTop: '1em' }}
            >
              {article.sections.map((section, index) => (
                <div key={index}>
                  <h2>{section.title}</h2>
                  <p>{section.content}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div style={{ flex: '1' }} />
      <div className={classes.pageFooter}>
        <PageFooter />
      </div>
    </div>
  )
}

export default ArticleTemplatePage
