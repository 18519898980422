import React from 'react'
import { AnchorButton } from '@blueprintjs/core'
import { useNavigate } from 'react-router-dom'
import { NavMenu } from '../componets/interfaces'
import { useDynamicTemplateStyles } from '../hooks/dynamicTemplateStyle'
import { getUser } from '../api'
import './app.css'

function LoginPage() {
  const navigate = useNavigate()
  const classes = useDynamicTemplateStyles({
    footerHeight: '5rem',
    headerHeight: '5rem',
  })

  const [found_user, set_found_user] = React.useState<boolean>(true)

  React.useLayoutEffect(() => {
    getUser().then((user) => {
      if (user) {
        navigate('/chat')
        return
      }

      set_found_user(false)
    })
  }, [])

  return (
    <div className={`${classes.wrapper} img-background`}>
      <div className={`${classes.pageHeader}`}>
        <div style={{ flex: '1' }} />
        <NavMenu />
      </div>
      <div
        className={classes.pageContent}
        style={{
          display: 'grid',
        }}
      >
        <div className="login-container">
          <div style={{ height: '30%' }} />
          <h1>Login</h1>
          <AnchorButton loading={found_user} large intent="primary" text="Google" href="/api/google" />
        </div>
      </div>
    </div>
  )
}

export default LoginPage
