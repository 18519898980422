import { Card } from '@blueprintjs/core'

import { NavMenu, PageFooter } from '../componets/interfaces'

import { useDynamicTemplateStyles } from '../hooks/dynamicTemplateStyle'
import './app.css'

function ThisIsNotLegalAdvicePage() {
  const classes = useDynamicTemplateStyles({
    footerHeight: '5rem',
    headerHeight: '5rem',
  })

  const COMPANY = 'Structured Technologies'
  const APP_NAME = 'Immigration Law Chat'

  return (
    <div className={`scrollable-page img-background`}>
      <div className={`${classes.pageHeader}`}>
        <div style={{ flex: '1' }} />
        <NavMenu />
      </div>
      <div className={'page-content-container'}>
        <div className={'page-content'}>
          <Card>
            <h1
              style={{
                fontSize: '2rem',
              }}
            >
              Legal Disclaimer for AI Chat Agent
            </h1>
            <p>
              This AI Chat Agent (hereinafter referred to as the "Agent") is provided by {APP_NAME}, a{COMPANY} company
              (hereinafter referred to as the "Company"). The Agent is intended for general information and
              communication purposes only and does not constitute legal advice or establish an attorney-client
              relationship. The information provided by the Agent should not be relied upon as a substitute for
              obtaining independent legal advice from a qualified attorney licensed in your jurisdiction.
            </p>
            <p>
              By using the Agent, you understand and agree that the Company, its affiliates, and their respective
              officers, directors, employees, and agents (collectively, the "Released Parties") shall not be liable for
              any direct, indirect, incidental, special, consequential, punitive or other damages, including but not
              limited to, loss of profits, data, or goodwill, arising out of or relating to your use of, or inability to
              use, the Agent or any actions taken in reliance on the information provided by the Agent, whether based on
              warranty, contract, tort, or any other legal theory, and whether or not the Released Parties have been
              advised of the possibility of such damages.
            </p>
            <p>
              You further agree to indemnify, defend, and hold harmless the Released Parties from and against any and
              all claims, liabilities, damages, losses, or expenses, including legal fees and costs, arising out of or
              in any way connected with your access to or use of the Agent or your reliance on any information provided
              by the Agent.
            </p>
            <p>
              The Agent may contain links to third-party websites, which are not under the control of the Company. The
              Company is not responsible for the content, accuracy, or opinions expressed on such websites, and the
              inclusion of any such link does not imply endorsement by the Company of the site or any association with
              its operators.
            </p>
            <p>
              The Company reserves the right to modify or discontinue, temporarily or permanently, the Agent, or any
              part thereof, with or without notice, at any time. By using the Agent, you agree that the Company shall
              not be liable to you or to any third party for any modification, suspension, or discontinuance of the
              Agent or any part thereof.
            </p>
            <p>
              Your use of the Agent and any dispute arising out of or relating to your use of the Agent shall be
              governed by and construed in accordance with the laws of {COMPANY}, without regard to its conflicts of law
              provisions. You agree to submit to the exclusive jurisdiction of the courts located within {COMPANY} to
              resolve any dispute arising out of or relating to your use of the Agent or the information provided by the
              Agent.
            </p>
            <p>
              By using the Agent, you acknowledge that you have read, understood, and agree to be bound by this Legal
              Disclaimer. If you do not agree to this Legal Disclaimer, please do not use the Agent.
            </p>
          </Card>
        </div>
      </div>
      <div className={classes.pageFooter}>
        <PageFooter />
      </div>
    </div>
  )
}

export default ThisIsNotLegalAdvicePage
