import type {
  ChatMessage,
  ChatReducer,
  ModelState,
  WebLink,
  ServiceIcon,
  ChatState,
  ThreadMessage,
  Thread,
} from '../types'

function url_to_icon(url: String): ServiceIcon {
  if (url.includes('www.uscis.gov')) return 'DOS'
  if (url.includes('travel.state.gov')) return 'DHS'
  return 'DOS'
}

function url_to_instruction(url: String) {
  function get_last_part_of_url(url: String) {
    // Remove trailing slash if it exists
    if (url.endsWith('/')) {
      url = url.slice(0, -1)
    }
    const urlParts = url.split('/')
    const lastPart = urlParts.pop()
    const lastPartHandle = lastPart?.split('.').at(0)

    return lastPartHandle?.replaceAll('-', ' ')?.replaceAll('_', ' ')
  }

  return `${get_last_part_of_url(url)}`
}

export function chatStateFromThread(thread: Thread): ChatState {
  const state: ChatState = {
    model_state: 'READY',
    chat_history: [],
    thread_id: thread.id,
  }

  thread.messages.forEach((thread_message: ThreadMessage) => {
    const structured_web_links: Array<WebLink> = []

    thread_message.links.forEach((url) => {
      const web_link: WebLink = {
        instruction: url_to_instruction(url),
        url: url,
        icon: url_to_icon(url),
      }
      structured_web_links.push(web_link)
    })

    const chat_message: ChatMessage = {
      user_short_id: thread_message.is_user ? 'n/a' : 'ai',
      timestamp: '',
      message: thread_message.message,
      message_id: String(thread_message.message_id),
      links: structured_web_links,
      suggestions: thread_message.suggestions,
      downloads: thread_message.downloads
    }

    state.chat_history.push(chat_message)
  })

  return state
}

export function addMessage(
  user_short_id: String,
  timestamp: String,
  message: String,
  message_id: String,
  links: Array<String>,
  suggestions: Array<String>,
  downloads: Record<string, String>
): ChatReducer {
  return (state) => {
    if (!message_id) message_id = '-1'
    if (!links) links = []

    const structured_web_links: Array<WebLink> = []

    links.forEach((url) => {
      const web_link: WebLink = {
        instruction: url_to_instruction(url),
        url: url,
        icon: url_to_icon(url),
      }

      structured_web_links.push(web_link)
    })

    const new_message: ChatMessage = {
      user_short_id: user_short_id,
      timestamp: timestamp,
      message: message,
      message_id: message_id,
      links: structured_web_links,
      suggestions: suggestions,
      downloads: downloads
    }
    return {
      ...state,
      chat_history: [...state.chat_history, new_message],
    }
  }
}

export function changeModelState(model_state: ModelState): ChatReducer {
  return (state) => {
    return {
      ...state,
      model_state: model_state,
    }
  }
}

export function setThreadId(thread_id: Number | null): ChatReducer {
  return (state) => {
    return {
      ...state,
      thread_id: thread_id,
    }
  }
}
