export function userShortId(user: User): String {
  return `${user.given_name.charAt(0)}${user.family_name.charAt(0)}`
}

export type ThreadInfo = {
  id: Number
  topic: String
}

export type ThreadMessage = {
  comment: String
  is_user: Boolean
  links: Array<String>
  message: String
  message_id: Number
  rating: Number
}

export type Thread = {
  id: Number
  messages: Array<ThreadMessage>
  user_id: Number
}

export type User = {
  sub: String
  given_name: String
  family_name: String
  nickname: String
  name: String
  picture: String
  locale: String
  updated_at: String

  email: String
  credits: Number
}

export type ServiceIcon = 'DOS' | 'DHS'

export type WebLink = {
  instruction: String
  url: String
  icon: ServiceIcon
}

export type ChatMessage = {
  user_short_id: String
  timestamp: String
  message: String
  message_id: String
  links: Array<WebLink> // a list of urls that we used to generate the response
  suggestions: Array<String> // a list of AI generated follow up questions
  downloads: Record<string, String> // { file_name: url_for_file }
}

export type ModelState = 'GENERATING' | 'READY'

export type ChatState = {
  model_state: ModelState
  chat_history: Array<ChatMessage>
  thread_id: Number | null
}

export const INIT_CHAT_STATE: ChatState = {
  model_state: 'READY',
  chat_history: [],
  thread_id: null,
}

export type ChatReducer = (arg0: ChatState) => ChatState
export type SetChatState = (arg0: ChatReducer | ChatState) => void
