import * as ReactDOMClient from 'react-dom/client'
import './index.css'

// setup routes w/ react router
// https://reactrouter.com/en/main/routers/create-browser-router
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

// Setup Blueprint
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css'

// Turn off focus styling for blueprint components
import { FocusStyleManager } from '@blueprintjs/core'
FocusStyleManager.onlyShowFocusOnTabs()

import { getThread, getUser, getUserThreads } from './api'
import AboutUsPage from './pages/aboutUsPage'
import { bVisa, e3Visa } from './pages/articles/articles'
import ArticlesPage from './pages/articles/articlesPage'
import ArticleTemplatePage from './pages/articles/articleTemplate'
import Chat from './pages/chat'
import ContactUsPage from './pages/contactUsPage'
import ThisIsNotLegalAdvicePage from './pages/disclaimer'
import HistoryPage from './pages/historyPage'
import LandingPage from './pages/landingPage'
import LoginPage from './pages/loginPage'
import ProfilePage from './pages/profilePage'
import PurchaseSuccessPage from './pages/purchaseSuccess'

function useGoogleAnalytics() {
  window.dataLayer = window.dataLayer || []
  function gtag() {
    dataLayer.push(arguments)
  }
  gtag('js', new Date())
  return gtag
}

function sendPageViewEvent(gtag, page_location) {
  gtag('event', 'page_view', {
    page_location: page_location,
    user_agent: navigator.userAgent,
  })
}

async function init_app() {
  // https://developers.google.com/tag-platform/gtagjs/reference/events
  const gtag = useGoogleAnalytics()

  // configure with our google analytics MEASUREMENT ID
  gtag('config', 'G-MBNG8YM5E9')

  const router = createBrowserRouter([
    {
      path: '/login',
      loader: async () => {
        // warm up call to get user
        getUser().then((user) => { })
        sendPageViewEvent(gtag, '/login')
        return null
      },
      element: <LoginPage />,
    },
    {
      path: '/',
      loader: async () => {
        sendPageViewEvent(gtag, '/')
        return null
      },
      element: <LandingPage />,
    },
    {
      path: '/about',
      loader: async () => {
        sendPageViewEvent(gtag, '/about')
        return null
      },
      element: <AboutUsPage />,
    },
    {
      path: '/chat?/:thread_id',
      loader: async ({ params }) => {
        const thread_id = parseInt(params.thread_id)
        sendPageViewEvent(gtag, '/chat')
        return {
          user: await getUser(),
          user_threads: await getUserThreads(),
          current_thread: thread_id ? await getThread(thread_id) : null,
        }
      },
      element: <Chat />,
      errorElement: <LoginPage />,
    },
    {
      path: '/profile',
      loader: async () => {
        sendPageViewEvent(gtag, '/profile')
        return {
          user: await getUser(),
          user_threads: await getUserThreads(),
        }
      },
      element: <ProfilePage />,
      errorElement: <LoginPage />,
    },
    {
      path: '/history',
      loader: async () => {
        sendPageViewEvent(gtag, '/history')
        return {
          user: await getUser(),
          user_threads: await getUserThreads(),
        }
      },
      element: <HistoryPage />,
      errorElement: <LoginPage />,
    },
    {
      path: '/disclaimer',
      loader: async () => {
        sendPageViewEvent(gtag, '/disclaimer')
        return null
      },
      element: <ThisIsNotLegalAdvicePage />,
    },
    {
      path: '/contact',
      loader: async () => {
        sendPageViewEvent(gtag, '/contact')
        return null
      },
      element: <ContactUsPage />,
    },
    {
      path: '/success/:session_id',
      loader: async () => {
        sendPageViewEvent(gtag, '/success')
        return null
      },
      element: <PurchaseSuccessPage />,
    },
    {
      path: '/articles',
      loader: async () => {
        sendPageViewEvent(gtag, '/articles')
        return null
      },
      element: <ArticlesPage />,
    },
    // Articles
    {
      path: '/articles/what-is-an-e3-visa',
      loader: async () => {
        sendPageViewEvent(gtag, '/articles/what-is-an-e3-visa')
        return null
      },
      element: <ArticleTemplatePage article={e3Visa} />,
    },
    {
      path: '/articles/what-is-a-b-visa',
      loader: async () => {
        sendPageViewEvent(gtag, '/articles/what-is-a-b-visa')
        return null
      },
      element: <ArticleTemplatePage article={bVisa} />,
    },
  ])

  const container = document.getElementById('root')
  const root = ReactDOMClient.createRoot(container)
  root.render(<RouterProvider router={router} />)
}

init_app()
