import { useNavigate } from 'react-router-dom'
import { NavMenu, PageFooter } from '../componets/interfaces'

import { Card } from '@blueprintjs/core'
import { useDynamicTemplateStyles } from '../hooks/dynamicTemplateStyle'
import './app.css'

const shane = new URL('../assets/shane.jpg', import.meta.url)
const sep = new URL('../assets/sep.jpg', import.meta.url)
const phil = new URL('../assets/phil.png', import.meta.url)

function ProfileCard(props: { name: string; subtitle: string; img_url: string; content: string }) {
  const { name, subtitle, img_url, content } = props
  return (
    <Card style={{ margin: '24px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <img
          loading="lazy"
          src={img_url}
          style={{
            height: '4.5rem',
            borderRadius: '4.5rem',
            border: '1px gold solid',
          }}
          alt="Customer Profile Icon"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          <h2 style={{ margin: '1px' }}>{name}</h2>
          <span style={{ margin: '1px' }}>{subtitle}</span>
        </div>
      </div>
      <br />
      <div>
        <p>{content}</p>
      </div>
    </Card>
  )
}

function AboutUsPage() {
  const navigate = useNavigate()

  const classes = useDynamicTemplateStyles({
    footerHeight: '5rem',
    headerHeight: '5rem',
  })

  return (
    <div className={`scrollable-page img-background`}>
      <div className={`${classes.pageHeader}`}>
        <div style={{ flex: '1' }} />
        <NavMenu />
      </div>

      <div className={'page-content-container'}>
        <div className={'page-content'}>
          <div
            style={{
              padding: '1rem',
              overflow: 'auto',
            }}
          >
            <h1>What We Do</h1>

            <div style={{ height: '10px' }}></div>

            <p>We provide an AI chat for US immigration questions.</p>

            <p>
              Born from frustration and experience struggling with US immigration. We came together to build something
              we think can help.
            </p>

            <div style={{ textAlign: 'center', margin: '24px' }}>
              <h2>
                <i>our mission is to help simplify US immigration</i>
              </h2>
            </div>

            <p>
              We're dedicated to simplifying the complex web of U.S. immigration. We recognize that language barriers
              and navigating various government agencies can be overwhelming. We hope to ease this burden by providing a
              user-friendly, chat-based platform that consolidates essential information and delivers personalized
              guidance for your unique immigration needs.
            </p>

            <div style={{ height: '16px' }}></div>

            <h1>Meet the Makers</h1>

            <div style={{ height: '10px' }}></div>

            <p>A team of thoughtful and passionate people</p>

            <ProfileCard
              name="Phillip Haeusler"
              subtitle="Builder, Innovator, AI Specialist"
              img_url={phil.toString()}
              content="I'm an Australian who has lived through the stress and anxiety of obtaining a work visa for San
            Francisco. It's painful, but the experience of living in the US is transformative. I want to help others on
            this journey. I believe immigration is foundational to the US, and I'm certain that AI can increasingly help
            address these complex processes."
            />

            <ProfileCard
              name="Shane Wade"
              subtitle="Engineer"
              img_url={shane.toString()}
              content="It took a long time to realize the impact that immigration has on America. Things really hit home for me when
          I moved to San Francisco and met so many talented people from all over the world. I want to give back to the
          communities that have contributed so much to the innovation we all enjoy."
            />

            <ProfileCard
              name="Sepehr Ardebilianfard"
              subtitle="Figure Outer, Builder"
              img_url={sep.toString()}
              content="As an
            immigrant myself, it wasn't too long ago when I was 13 years old and the one lost trying to figure out how
            to get answers online and translate them to my parents who were having a rough time learning English.
            Immigration to any country is already hard enough; getting your questions answered should not be one of the
            many things you worry about in this journey."
            />
          </div>
        </div>
      </div>
      <div className={classes.pageFooter}>
        <PageFooter />
      </div>
    </div>
  )
}

export default AboutUsPage
