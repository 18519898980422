import { AnchorButton } from '@blueprintjs/core'

import { NavMenu, PageFooter } from '../componets/interfaces'

import { useDynamicTemplateStyles } from '../hooks/dynamicTemplateStyle'
import './app.css'

function ContactUsPage() {
  const classes = useDynamicTemplateStyles({
    footerHeight: '5rem',
    headerHeight: '5rem',
  })

  const CONTACT_EMAIL = 'info@structured.foundation'

  return (
    <div className={`scrollable-page img-background`}>
      <div className={`${classes.pageHeader}`}>
        <div style={{ flex: '1' }} />
        <NavMenu />
      </div>
      <div className={'page-content-container'}>
        <div className={'page-content'} style={{ width: '600px', minHeight: '100%' }}>
          <div
            style={{
              padding: '1rem',
            }}
          >
            <h1>Contact Us</h1>
            <p>We would love to hear from you!</p>

            <div style={{ height: '24px' }}></div>

            <AnchorButton
              large
              style={{
                borderRadius: '8px',
                backgroundColor: '#383258',
                color: 'whitesmoke',
              }}
              text="Email Us"
              href={'mailto: ' + CONTACT_EMAIL}
            />

            <div style={{ height: '24px' }}></div>

            <AnchorButton
              large
              style={{
                borderRadius: '8px',
                backgroundColor: '#383258',
                color: 'whitesmoke',
              }}
              text="Feedback Form"
              href="https://docs.google.com/forms/d/e/1FAIpQLSd0Rw2Gn-RG81jRrebAq-m0debgF7jqVxcISEOpb2dW1uSFAQ/viewform?"
            />
          </div>
        </div>
      </div>
      <div style={{ flex: '1' }}></div>

      <div className={classes.pageFooter}>
        <PageFooter />
      </div>
    </div>
  )
}

export default ContactUsPage
