import { AnchorButton, Button, Card } from '@blueprintjs/core'

import React from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'

import { useDynamicTemplateStyles } from '../hooks/dynamicTemplateStyle'

import { PageFooter, ProfileNavMenu } from '../componets/interfaces'

import { ThreadInfo, User } from '../types'

import './app.css'

function HistoryPage() {
  const navigate = useNavigate()
  const { user, user_threads } = useLoaderData() as { user: User; user_threads: Array<ThreadInfo> }

  // This is a component short circuit that
  // initializes a useEffect hook and then returns an empty div
  if (user == null) {
    React.useEffect(() => {
      console.log('Not user logged in: redirecting to login')
      setTimeout(() => {
        navigate('/login')
      }, 250)
    }, [])

    return <div></div>
  }

  const classes = useDynamicTemplateStyles({
    footerHeight: '5rem',
    headerHeight: '5rem',
  })

  const reversed_threads = [...user_threads].reverse()

  return (
    <div className={`${classes.wrapper} img-background`}>
      <div className={classes.pageHeader}>
        <Button minimal text="Back To Chat" icon="undo" onClick={() => navigate('/chat')} />
        <div style={{ flexGrow: 1 }}></div>
        <ProfileNavMenu user={user} threads={user_threads} />
      </div>

      <div className={classes.pageContent}>
        <div
          style={{
            padding: '1rem',
          }}
        >
          <h1
            style={{
              margin: 0,
            }}
          >
            History
          </h1>
        </div>

        <div
          style={{
            padding: '1rem',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '.8rem',
          }}
        >
          {reversed_threads.map((thread, index) => (
            <Card
              key={index}
              elevation={2}
              interactive
              onClick={() => {
                navigate(`/chat/${thread.id}`)
              }}
            >
              {thread.topic}
            </Card>
          ))}
        </div>
      </div>

      <div className={classes.pageFooter}>
        <PageFooter />
      </div>
    </div>
  )
}

export default HistoryPage
