import { Toaster } from '@blueprintjs/core'

// Singleton toaster instance
const toaster = Toaster.create({
  canEscapeKeyClear: true,
  maxToasts: 5,
  position: 'top',
  usePortal: true,
})

export default toaster
