import { Button, Checkbox, Collapse, Icon, Menu, MenuDivider, MenuItem, TextArea } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import React, { ReactNode } from 'react'

import { ServiceIcon, ThreadInfo, User } from '../types'

// Setup Blueprint
import '@blueprintjs/core/lib/css/blueprint.css'

import { Link, useNavigate } from 'react-router-dom'
export const logo_image_url = new URL('../assets/low_poly_torch.png', import.meta.url)

export const dhs_logo = new URL('../assets/us_dep_of_homeland_security.png', import.meta.url)
export const dos_logo = new URL('../assets/us_dep_of_state.png', import.meta.url)

function MenuInfo(props: { text: String }) {
  const { text } = props
  return <h4 className="bp4-menu">{text}</h4>
}

function NavigationMenuItems() {
  const navigate = useNavigate()
  return (
    <div>
      <MenuItem onClick={() => navigate('/')} text="Home" />
      <MenuItem onClick={() => navigate('/contact')} text="Contact" />
      <MenuItem onClick={() => navigate('/about')} text="About" />
      <MenuItem onClick={() => navigate('/disclaimer')} text="Disclaimer" />
    </div>
  )
}

export function NavMenu() {
  return (
    <Popover2
      minimal
      position="bottom-right"
      content={
        <Menu>
          <NavigationMenuItems />
        </Menu>
      }
    >
      <Button large minimal icon="menu" />
    </Popover2>
  )
}

export function ProfileNavMenu(props: { user: User; threads: Array<ThreadInfo> }) {
  const { user, threads } = props
  const reverse_threads = [...threads].reverse()
  const navigate = useNavigate()
  return (
    <Popover2
      minimal
      position="bottom-right"
      usePortal={false}
      content={
        <Menu>
          <MenuInfo text={user.email} />
          <MenuInfo text={`Credits: ${user.credits}`} />
          <MenuDivider />
          <MenuItem text="Manage Account" icon="user" onClick={() => navigate('/profile')} />
          <MenuItem
            text="History"
            icon="history"
            popoverProps={{
              hoverCloseDelay: 8000,
            }}
          >
            <MenuItem
              text="History"
              onClick={() => {
                navigate('/history')
              }}
            />
            <MenuDivider />
            <MenuInfo text="Recent" />
            {reverse_threads.slice(0, 4).map((thread, index) => (
              <MenuItem
                key={index}
                multiline={true}
                onClick={() => {
                  navigate(`/chat/${thread.id}`)
                }}
                text={thread.topic}
              />
            ))}
          </MenuItem>
          <MenuDivider />
          <NavigationMenuItems />
          <MenuDivider />
          <MenuItem text="Logout" icon="log-out" href="/api/logout" />
        </Menu>
      }
    >
      <Button large minimal icon="menu" />
    </Popover2>
  )
}

export function PageFooter() {
  return (
    <div
      style={{
        display: 'flex',
        gap: '5rem',
      }}
    >
      <Link className="footer-link" to="/">
        Home
      </Link>
      <Link className="footer-link" to="/about">
        About
      </Link>
      <Link className="footer-link" to="/contact">
        Contact
      </Link>
      <Link className="footer-link" to="/disclaimer">
        Disclaimer
      </Link>
    </div>
  )
}

export function LogoButton() {
  const navigate = useNavigate()
  return (
    <Button
      minimal
      intent={'primary'}
      style={{
        padding: '1px 1px 1px 1px',
      }}
      onClick={() => {
        navigate('/')
      }}
    >
      <img
        src={String(logo_image_url)}
        style={{
          width: '50px',
        }}
        alt="Immigration GPT Logo"
      />
    </Button>
  )
}

type FeedbackInputProps = {
  intent: 'positive' | 'negative'
  handleFeedback: (arg0: String) => void
  closePopover: () => void
}

export function FeedbackInput(props: FeedbackInputProps) {
  const { intent, handleFeedback, closePopover } = props

  const feedback_message = React.useRef<String>('')

  const negative_feedback_checkboxes: Record<string, boolean> = {
    "This isn't true": false,
    "This isn't helpful": false,
  }

  return intent == 'positive' ? (
    <div
      style={{
        padding: '0.8rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          marginBottom: '0.5rem',
          display: 'flex',
        }}
      >
        <Icon icon="thumbs-up" intent="success" />
        <div
          style={{
            paddingLeft: '0.8rem',
          }}
        >
          Provide additional feedback
        </div>
      </div>

      <TextArea
        growVertically
        large
        placeholder={'What do you like about the response?'}
        style={{
          minWidth: '20rem',
          borderRadius: '7px',
        }}
        onChange={(e) => {
          feedback_message.current = e.target.value
        }}
      />
      <br />
      <div
        style={{
          display: 'flex',
        }}
      >
        <div style={{ flexGrow: 1 }} />
        <Button
          minimal
          intent="primary"
          text="Submit feedback"
          onClick={() => {
            handleFeedback(feedback_message.current)
            closePopover()
          }}
        />
      </div>
    </div>
  ) : (
    <div
      style={{
        padding: '0.8rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          marginBottom: '0.5rem',
          display: 'flex',
        }}
      >
        <Icon icon="thumbs-down" intent="danger" />
        <div
          style={{
            paddingLeft: '0.8rem',
          }}
        >
          Provide additional feedback
        </div>
      </div>

      <TextArea
        growVertically
        large
        placeholder={'What was the issue with the response? How could it be improved?'}
        style={{
          minWidth: '20rem',
          borderRadius: '7px',
        }}
        onChange={(e) => {
          feedback_message.current = e.target.value
        }}
      />
      <br />
      {Object.keys(negative_feedback_checkboxes).map((label) => (
        <Checkbox
          key={label}
          label={label}
          onChange={() => {
            const prev_bool: Boolean = negative_feedback_checkboxes[label]
            negative_feedback_checkboxes[label] = !prev_bool
          }}
        />
      ))}
      <br />
      <div
        style={{
          display: 'flex',
        }}
      >
        <div style={{ flexGrow: 1 }} />
        <Button
          minimal
          intent="primary"
          text="Submit feedback"
          onClick={() => {
            let checkbox_msg = ''
            Object.entries(negative_feedback_checkboxes).forEach(([label, bool]) => {
              if (bool) checkbox_msg += `${label}, `
            })
            const final_msg = `${checkbox_msg} ${feedback_message.current}`.toLowerCase()
            handleFeedback(final_msg)
            closePopover()
          }}
        />
      </div>
    </div>
  )
}

export function ServiceIcon(props: { icon: ServiceIcon }) {
  const { icon } = props
  const icon_map: Record<ServiceIcon, string> = {
    DHS: dhs_logo.toString(),
    DOS: dos_logo.toString(),
  }
  const icon_url = icon_map[icon]
  return <img style={{ height: '2.2rem' }} src={icon_url} alt={icon} />
}

export function AutoCollapse(props: { text: String; children: ReactNode }) {
  const { text } = props
  const [open, setOpen] = React.useState<boolean>(false)

  return (
    <div>
      <Button
        large
        text={text}
        icon={open ? 'chevron-up' : 'chevron-down'}
        minimal
        style={{
          color: 'whitesmoke',
        }}
        onClick={() => {
          setOpen(!open)
        }}
      />
      <Collapse isOpen={open}>{props.children}</Collapse>
    </div>
  )
}

type ChatExamplesProps = {
  example_statements: Array<String>
  updateMessage: (msg: String) => void
}

export function ChatExamples(props: ChatExamplesProps) {
  const { example_statements, updateMessage } = props

  return (
    <div
      style={{
        height: '100%',
        display: 'grid',
      }}
    >
      <div
        style={{
          placeSelf: 'center',
          // textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        }}
      >
        <h2
          style={{
            margin: 0,
          }}
        >
          Examples
        </h2>
        <div
          style={{
            background: 'white',
            padding: '0.6rem',
            // paddingBottom: '0.6rem',
            borderRadius: '7px',
          }}
        >
          {example_statements.map((statement, index) => (
            <Button
              key={index}
              large
              fill
              alignText="left"
              minimal
              icon={<Icon icon="send-message" color="#FFC659" />}
              text={statement}
              onClick={() => {
                updateMessage(statement)
              }}
            ></Button>
          ))}
        </div>
      </div>
    </div>
  )
}
