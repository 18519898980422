import { AnchorButton, Button } from '@blueprintjs/core'
import { Stripe } from '@stripe/stripe-js/'
import { loadStripe } from '@stripe/stripe-js/pure'
import React from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'

import { useDynamicTemplateStyles } from '../hooks/dynamicTemplateStyle'

import { PageFooter, ProfileNavMenu } from '../componets/interfaces'

import { getStripeConfig, getStripeSession } from '../api'

import { ThreadInfo, User } from '../types'

import './app.css'

const CREDITS = 25
const PRICE = 6

async function initStripe(): Promise<Stripe | null> {
  const { public_key } = await getStripeConfig()
  const stripe = await loadStripe(public_key)
  return stripe
}

async function stripeCheckout(product_id: string, stripe: Stripe): Promise<void> {
  const { session_id } = await getStripeSession(product_id)
  stripe.redirectToCheckout({ sessionId: session_id })
}

const TEST_PRODUCT_ID_50_CREDS = 'prod_NZJgA3JIDbtc1n'

function ProfilePage() {
  const navigate = useNavigate()
  const { user, user_threads } = useLoaderData() as { user: User; user_threads: Array<ThreadInfo> }

  // This is a component short circuit that
  // initializes a useEffect hook and then returns an empty div
  if (user == null) {
    React.useEffect(() => {
      console.log('Not user logged in: redirecting to login')
      setTimeout(() => {
        navigate('/login')
      }, 250)
    }, [])

    return <div></div>
  }

  const classes = useDynamicTemplateStyles({
    footerHeight: '5rem',
    headerHeight: '5rem',
  })

  const stripe_ref = React.useRef<Stripe | null>(null)

  React.useLayoutEffect(() => {
    initStripe()
      .then((stripe) => {
        stripe_ref.current = stripe
      })
      .catch((err) => {
        console.warn('Failed to init Stripe')
      })
  }, [])

  // console.log('user in profile', user)

  return (
    <div className={`${classes.wrapper} img-background`}>
      <div className={classes.pageHeader}>
        <Button minimal text="Back To Chat" icon="undo" onClick={() => navigate('/chat')} />
        <div style={{ flexGrow: 1 }}></div>
        <ProfileNavMenu user={user} threads={user_threads} />
      </div>

      <div className={classes.pageContent} style={{ display: 'grid' }}>
        <div
          style={{
            minWidth: '8rem',
            height: 'fit-content',
            padding: '20px',
            borderRadius: '10px',
            placeSelf: 'center',
            background: 'white',
          }}
        >
          <h1
            style={{
              margin: 0,
            }}
          >
            Account
          </h1>
          <br />
          <div>
            <h3 style={{ margin: 0 }}>Name:</h3> <span>{`${user.given_name}`}</span>
          </div>
          <br />
          <div>
            <h3 style={{ margin: 0 }}>Email:</h3> <span>{`${user.email}`}</span>
          </div>
          <br />
          <div>
            <h3 style={{ margin: 0 }}>Credits:</h3> <span>{`${user.credits}`}</span>
          </div>
          <br />

          <div
            style={{
              width: '100%',
              border: '2px #383258 solid',
              borderRadius: '10px',
              padding: '1rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '.6rem',
            }}
          >
            <h2
              style={{
                margin: 0,
              }}
            >
              Purchase Credits
            </h2>

            <h3
              style={{
                margin: 0,
              }}
            >
              {` ${CREDITS} Credits for $${PRICE}`}
              <span style={{ color: 'gray' }}>*</span>
            </h3>
            <div />
            <Button
              style={{
                width: 'fit-content',
              }}
              intent="primary"
              icon="shopping-cart"
              text={`Checkout`}
              onClick={() => {
                if (!stripe_ref.current) return
                console.log('Redirecting to stripe payment!')
                stripeCheckout(TEST_PRODUCT_ID_50_CREDS, stripe_ref.current)
              }}
            />
            <div />
            <div>
              {CREDITS} credits = {CREDITS} messages.
            </div>
            <div>Each time you ask a question, 1 credit is used.</div>
            <div
              style={{
                fontSize: '0.8rem',
                color: 'grey',
              }}
            >
              *We accept any legal tender supported by{' '}
              <a
                href={'https://stripe.com/docs/currencies?presentment-currency=US#presentment-currencies'}
                target="_blank"
              >
                Stripe
              </a>
            </div>
          </div>
          <br />
          <AnchorButton minimal icon="log-out" text="Logout" href="/api/logout" />
        </div>
      </div>

      <div className={classes.pageFooter}>
        <PageFooter />
      </div>
    </div>
  )
}

export default ProfilePage
