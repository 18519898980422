import { createThread, putNewChatMessage, postMessageRating } from '../api'
import { addMessage, changeModelState, setThreadId } from '../reducers/chat'
import type { ChatState, SetChatState } from '../types'

import toaster from '../toaster'

function addMessageToThread(new_message: String, user_short_id: String, state: ChatState, setState: SetChatState) {
  let new_state = addMessage(user_short_id, Date.now().toString(), new_message)(state)
  new_state = changeModelState('GENERATING')(new_state)
  setState(new_state)

  putNewChatMessage(new_message, new_state.thread_id)
    .then(async (res) => {
      console.log(res.status)

      if (res.status == 402) {
        toaster.show({
          message: 'Out of credit!',
          timeout: 8000,
          intent: 'warning',
          action: {
            text: 'Buy more here',
            href: '/profile',
            icon: 'shopping-cart',
          },
        })
        new_state = changeModelState('READY')(new_state)
        setState(new_state)
        return
      }

      if (res.status == 400 || res.status == 500) {
        toaster.show({
          message: JSON.stringify(await res.json()),
          timeout: 3000,
          intent: 'warning',
        })
        new_state = changeModelState('READY')(new_state)
        setState(new_state)
        return
      }

      if (res.status == 429) {
        toaster.show({
          message: 'OpenAI is busy...',
          timeout: 5000,
          intent: 'none',
        })
        new_state = changeModelState('READY')(new_state)
        setState(new_state)
        return
      }

      if (res.status == 408) {
        toaster.show({
          message: 'Timed out waiting for OpenAI...',
          timeout: 5000,
          intent: 'none',
        })
        new_state = changeModelState('READY')(new_state)
        setState(new_state)
        return
      }

      if (res.status != 201) {
        toaster.show({
          message: JSON.stringify(await res.json()),
          timeout: 3000,
          intent: 'warning',
        })
        new_state = changeModelState('READY')(new_state)
        setState(new_state)
        return
      }

      const data: {
        thread_id: String
        message_id: String
        message: String
        links: Array<String>
        suggestions: Array<String>
        downloads: Record<string, String>
      } = await res.json()

      console.log(data)

      new_state = addMessage(
        'ai',
        Date.now().toString(),
        data.message,
        data.message_id,
        data.links ?? [],
        data.suggestions ?? [],
        data.downloads ?? {}
      )(new_state)
      new_state = changeModelState('READY')(new_state)

      setState(new_state)
    })
    .catch((err) => {
      console.error(err)
      new_state = changeModelState('READY')(new_state)
      setState(new_state)
    })
}

export function submitMessageAndGenerateResponse(
  new_message: String,
  user_short_id: String,
  state: ChatState,
  setState: SetChatState
) {
  // Create a thread if necessary
  if (state.chat_history.length == 0) {
    createThread()
      .then(async (res) => {
        if (res.status == 201) {
          const data = await res.json()
          let new_state = setThreadId(data.thread_id)(state)
          addMessageToThread(new_message, user_short_id, new_state, setState)
          return
        } else {
          toaster.show({
            message: 'Failed to create a thread!',
            timeout: 3000,
            intent: 'danger',
          })
          return
        }
      })
      .catch((err) => {
        console.error(err)
        toaster.show({
          message: 'Failed to create a thread!',
          timeout: 3000,
          intent: 'danger',
        })
        return
      })
  } else {
    addMessageToThread(new_message, user_short_id, state, setState)
  }
}

export function copyToClipboard(text: String, toast_message: String) {
  navigator.clipboard.writeText(text.toString()).then(() => {
    toaster.show({
      message: toast_message,
      intent: 'success',
    })
  })
}
