import { Article } from './articleTemplate'

export const bVisa: Article = {
  title: 'Understanding the B Temporary Travel Visa',
  subtitle: 'A Guide to B1 and B2 Visas for Business and Tourism in the United States',
  sections: [
    {
      title: 'What Is a B1/B2 Visa?',
      content:
        'The B1 visa and B2 visa are temporary, non-immigrant visas that allow the holder to travel to the United States for either business or tourism purposes. Almost all reasons for temporary travel are covered by B visas except student travel, which is covered under F-1 visas. The B-1 visa covers business trips, while the B-2 visa covers tourism, such as vacations or visiting family.',
    },
    {
      title: 'B-1/B-2 Visa Cost',
      content:
        'The government filing fee for a B visa is $160. Note this does not include the cost of gathering documents and evidence and acquiring passport photos. Beginning May 30, 2023, the cost of nonimmigrant visa applications will be going up by 15%. Learn more about the U.S. Department of State’s announcement and the costs you can expect for each visa type.',
    },
    {
      title: 'Processing Time',
      content:
        'The average wait time for a B1/B2 visa interview appointment is currently two months. To check the wait time for your specific embassy or consulate, enter your city in this handy State Department tool under the section “Appointment Wait Time.” Note that if you are applying for an interview in a country other than your home country, wait times may be longer.',
    },
    {
      title: 'Requirements',
      content:
        'The B1/B2 visa covers a variety of reasons for travel to the United States that includes both business and tourism/non-business purposes. Reasons for travel under a B1 visa include conducting business, attending a conference, and settling an estate. Reasons for travel under a B2 visa include engaging in tourist activities, visiting family, and receiving medical treatment. A B1 visa and B2 visa do not grant permanent resident status but the holder can apply for a green card. The maximum amount of time issued for B visas is 180 days. These visas allow for multiple entries into the United States.',
    },
    {
      title: 'B1/B2 Visa Eligibility',
      content:
        'To be eligible for a B1 visa or B2 visa, the applicant must prove that the travel is temporary and they have the ability to self-fund all costs of the trip. Additionally, they must prove that they have no intent of remaining in the United States beyond their stay and abandoning a residence outside of the United States. The Visa Waiver Program allows nationals from certain countries to travel to the United States without a visa for business, tourism, or while in transit for up to 90 days.',
    },
    {
      title: 'Required Documents',
      content:
        'Documents needed to apply for a B1/B2 visa include a passport valid for six months past the date of return, a recent digital photograph that meets government requirements, documentation of the past five previous trips to the United States, proof of funds to cover the entire cost of the trip, and proof of binding ties to the applicant’s home country.',
    },
    {
      title: 'B1/B2 Visa Application Process',
      content:
        'Applications are processed by the United States Embassy or consulate in your country. You will need to attend an interview before your visa is approved. The process involves completing the Online Non-Immigrant Application, Form DS-160, uploading your digital photo, and attending the visa interview. After the interview, the consular officer may request additional documents or information to make a decision about your case.',
    },
  ],
}

export const e3Visa: Article = {
  title: 'Understanding the E3 Visa for Australian Professionals',
  subtitle: 'A Guide to E3 Visas in the United States',
  sections: [
    {
      title: 'What Is an E3 Visa?',
      content:
        'The E3 visa is a non-immigrant visa specifically designed for Australian citizens who wish to work in a specialty occupation in the United States. The E3 visa allows Australian professionals to live and work in the U.S. while also permitting their spouse and unmarried children under the age of 21 to accompany them. Spouses of E3 visa holders are eligible to apply for work authorization.',
    },
    {
      title: 'E3 Visa Requirements',
      content:
        "To be eligible for an E3 visa, the applicant must be an Australian citizen, have a valid job offer from a U.S. employer for a specialty occupation, and possess the necessary qualifications (such as a bachelor's degree or equivalent experience) for the position. The offered job must require a minimum of a bachelor's degree in a specific field, and the applicant must have relevant education or work experience in that field.",
    },
    {
      title: 'E3 Visa Cost',
      content:
        'The government filing fee for an E3 visa is $205. This does not include the cost of gathering required documents, obtaining passport photos, or any fees related to obtaining the necessary academic or work experience evaluations. The employer may also be required to pay additional costs for the Labor Condition Application (LCA) process.',
    },
    {
      title: 'Processing Time',
      content:
        'The processing time for an E3 visa varies depending on the U.S. consulate or embassy where the application is submitted. Generally, the processing time ranges from a few weeks to a few months. Premium processing is not available for E3 visas, so applicants should plan accordingly and apply well in advance of their intended start date.',
    },
    {
      title: 'E3 Visa Application Process',
      content:
        'The E3 visa application process involves several steps, including obtaining a job offer from a U.S. employer, filing a Labor Condition Application (LCA) with the U.S. Department of Labor, completing the Online Non-Immigrant Visa Application (Form DS-160), scheduling and attending a visa interview at a U.S. consulate or embassy, and entering the United States with a valid E3 visa.',
    },
    {
      title: 'E3 Visa Duration and Renewal',
      content:
        'The E3 visa is initially granted for a period of up to two years. However, it can be renewed indefinitely in two-year increments, as long as the visa holder continues to meet the eligibility requirements and maintain their employment status. Renewal of the E3 visa typically involves the same application process, including obtaining a new LCA and attending a visa interview.',
    },
    {
      title: 'Changing Employers on an E3 Visa',
      content:
        'E3 visa holders are allowed to change employers, but they must obtain a new job offer and file a new LCA with the U.S. Department of Labor. After obtaining the approved LCA, the new employer must file a new Form I-129 petition with USCIS on behalf of the E3 visa holder. The E3 visa holder can start working for the new employer once USCIS approves the new petition.',
    },
  ],
}
