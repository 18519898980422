import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(() => ({
  wrapper: {
    height: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  pageHeader: {
    // backgroundColor: 'gray',
    display: 'flex',
    flexDirection: 'row',
    height: (props) => props.theme.headerHeight,
    padding: '0.8rem',
  },
  pageContent: {
    // backgroundColor: 'lightblue',
    height: (props) => `calc(100% - ${props.theme.headerHeight})`,
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    width: '100%',
  },
  pageFooter: {
    display: 'none',
  },
  flexSpacer: {
    flexGrow: 1
  },

  '@media (min-width: 800px)': {
    pageContent: {
      height: (props) => `calc(100% - ${props.theme.headerHeight} - ${props.theme.footerHeight})`,
      maxWidth: '800px',
      margin: '0 auto',
    },
    pageFooter: {
      height: (props) => props.theme.footerHeight,
      width: '100%',
      // backgroundColor: 'lightgray',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
    },
  },
}))

type DynamicTemplateStylesProps = {
  headerHeight: String
  footerHeight: String
}

export function useDynamicTemplateStyles(props: DynamicTemplateStylesProps) {
  return useStyles({ theme: props })
}
